import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { FC } from 'react';
import styles from './LogItem.module.scss';
import { LogItemProps } from './LogItem.props';

const LogItem: FC<LogItemProps> = ({ attributes, id }) => (
  <section className={styles.container} id={`log-item-${id}`}>
    <h2 className={styles.containerTitle}>
      {attributes?.version !== '' && `${attributes?.version} - `}
      {attributes?.title}
    </h2>
    <div className={styles.containerDate}>
      <CalendarOutlined width="18px" />
      <p className={styles.containerText}>{dayjs(attributes?.date, 'YYYY-MM-DD').format('MMM DD YYYY')}</p>
    </div>
    <h3 className={styles.containerSubtitle}>{attributes?.subtitle !== '' && attributes?.subtitle}</h3>

    {/* descriptions */}
    {!!attributes?.description && (
      <>
        {attributes?.description.length !== 0 ? (
          attributes?.description.map((desc) => (
            <div key={desc?.id}>
              <h2 className={`${styles.logDescriptionTypeTitle} logDescriptionType__${desc?.type.toLowerCase()}`}>
                {desc?.type}:
              </h2>
              <div className={styles.descriptionText} dangerouslySetInnerHTML={{ __html: `${desc?.details}` }} />
            </div>
          ))
        ) : (
          <p>no description</p>
        )}
      </>
    )}
  </section>
);

export { LogItem };
