import { Space, Tag } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import { PostCategoryEntity, PostTagEntity } from 'services/graphql/cms';
import { PostItemInfo } from '../../../BlogUi';
import { TContent } from '../../../BlogUi/PostItemInfo/PostItemInfo.props';
import { SocialShare } from '../../../SocialShare';
import styles from './BlogDetailsPage.module.scss';
import { BlogDetailsPageProps } from './BlogDetailsPage.props';

const BlogDetailsPage: FC<BlogDetailsPageProps> = ({ post }) => {
  const [blogUrl, setBlogUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setBlogUrl(window.location.href);
    }
  }, []);

  const imgUrl = post?.attributes?.featuredImage?.data?.attributes?.url;
  const imgAlt = post?.attributes?.featuredImage?.data?.attributes?.alternativeText;

  return (
    <article>
      <Space>
        {!!post?.attributes?.post_categories?.data?.length &&
          post?.attributes?.post_categories?.data.map((btn: PostCategoryEntity) => (
            <Link key={btn?.attributes?.slug} href={`/category/${btn?.attributes?.slug}`}>
              <Tag key={btn.id} className={styles.categoryTag}>
                {btn?.attributes?.name}
              </Tag>
            </Link>
          ))}
      </Space>

      <h1 className={styles.title}>{post?.attributes?.title}</h1>

      <div className={styles.info}>
        <PostItemInfo
          publishDate={post?.attributes?.publishDate}
          showPublishDate={post?.attributes?.showPublishDate}
          content={post?.attributes?.content as TContent[]}
          readingTime={post?.attributes?.reading_time}
        />

        <SocialShare postUrl={blogUrl} title={post?.attributes?.title} summary={post?.attributes?.excerpt} />
      </div>

      <div className={styles.featuredImg}>
        <Image
          src={imgUrl || ''}
          alt={imgAlt || post?.attributes?.title || 'Post featured Image'}
          width={1027}
          height={578}
        />
      </div>

      <div className={styles.richTextWrapper}>
        {post?.attributes?.content.map((data: any) => {
          if (data.richtext) {
            return (
              <div className={styles.richTextDiv} key={data.id} dangerouslySetInnerHTML={{ __html: data.richtext }} />
            );
          }

          if (data.image) {
            return (
              <div className={styles.contentImage} key={data.id} style={{ position: 'relative' }}>
                <img
                  src={data.image.data.attributes.url || ''}
                  alt={data.image.data.attributes.alt || post?.attributes?.title || 'Post content image'}
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            );
          }
        })}

        <br />
        <Space>
          {post?.attributes?.post_tags?.data?.length! > 0 &&
            post?.attributes?.post_tags?.data.map((btn: PostTagEntity) => (
              <Link key={btn?.attributes?.slug} href={`/tag/${btn?.attributes?.slug}`}>
                <Tag key={btn.id} className={styles.articleTagItem}>
                  #{btn?.attributes?.name}
                </Tag>
              </Link>
            ))}
        </Space>
        <br />
        <br />
      </div>

      {/* TODO when we have google analytics */}
      {/* <div className={styles.recommendPost}>
                <p className={styles.text}>Would you recommend this article?</p>

                <Button type="link" className={styles.linkBtn}>
                  Yes
                </Button>

                <Button type="link" className={styles.linkBtn}>
                  No
                </Button>
              </div> */}
    </article>
  );
};

export { BlogDetailsPage };
