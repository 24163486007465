import { FC } from 'react';
import { Breadcrumb } from 'antd';
import { useIntl } from 'react-intl';
import styles from './BreadCrumb.module.scss';

type TRenderLink = (url: string, text: string) => React.ReactElement;
type Props = {
  breadCrumb: number[];
  pageName: string;
  renderLink?: TRenderLink;
};
const CustomLink: FC<{ url: string; text: string; renderLink?: TRenderLink }> = ({ url, text, renderLink }) => {
  if (renderLink) {
    return renderLink(url, text);
  }

  return <a href={url}>{text}</a>;
};
const BreadCrumb: FC<Props> = ({ pageName, breadCrumb, renderLink }) => {
  const { formatMessage } = useIntl();
  const f = (id: string) => formatMessage({ id });

  const home = f(`${pageName}.breadCrumb.home`);
  const url = f(`${pageName}.breadCrumb.home.url`);

  return (
    <Breadcrumb className={styles.container}>
      {home !== 'null' && (
        <Breadcrumb.Item className={styles.containerItem}>
          <CustomLink url={url} text={home} renderLink={renderLink} />
        </Breadcrumb.Item>
      )}
      {breadCrumb.map((item) => {
        const itemTitle = f(`${pageName}.breadCrumb.item.${item}.title`);
        const itemUrl = f(`${pageName}.breadCrumb.item.${item}.url`);

        return (
          <Breadcrumb.Item key={item} className={styles.containerItem}>
            {itemUrl !== 'null' ? <CustomLink url={itemUrl} text={itemTitle} renderLink={renderLink} /> : itemTitle}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export { BreadCrumb };
