import { CustomBreadcrumb } from '../../../CustomBreadcrumb';
import { LinkItem } from '../../../CustomBreadcrumb/CustomBreadcrumb.props';
import { FC, useCallback, useEffect, useState } from 'react';
import styles from './AllFeaturesPage.module.scss';
import { GetMenuFeaturedPages } from 'services/graphql/cms';
import { Col, Grid, Row, Typography } from 'antd';
import Image from 'next/image';
import SingleFeature from './components/SingleFeature.component';
interface AllFeaturesPageProps {
  data: GetMenuFeaturedPages;
  appCode: string;
}

const { Paragraph, Title } = Typography;

export const AllFeaturesPage: FC<AllFeaturesPageProps> = ({ data, appCode }) => {
  const { md } = Grid.useBreakpoint();
  const [activeFeaturePage, setActiveFeaturePage] = useState<any>();

  const breadcrumbs: LinkItem[] = [
    {
      href: '/',
      slug: 'home',
      title: 'Home',
    },
    {
      href: '/features',
      slug: 'features',
      title: 'Features',
    },
  ];

  useEffect(() => {
    if (data?.featuresPages?.data?.length && data?.featuresPages?.data?.length > 0) {
      setActiveFeaturePage(data.featuresPages.data[0]);
    }
  }, [data?.featuresPages?.data]);

  const handleItemClick = useCallback((dataInput: any) => {
    setActiveFeaturePage(dataInput);
  }, []);

  return (
    <div className={styles.featuresPageContainer}>
      <div className={styles.breadcrumbContainer}>
        <CustomBreadcrumb items={breadcrumbs} />
      </div>

      <div>
        <Title className={styles.featuresTitleContainer}>A sea of features</Title>
      </div>

      <div>
        <Paragraph className={styles.featuresDescriptionContainer}>
          With logbook, inventory, documents, checklists, tasks and alerts over cloud services, TheBoatApp, powered by
          TheBoatDB, is all about having the best experience in managing your boat efficiently and effectively.
        </Paragraph>
      </div>

      {md ? (
        <Row style={{ display: 'flex', gap: '20px' }}>
          <Col span={md ? 14 : 24} className={styles.featuresPageItemsContainer}>
            {data?.featuresPages?.data?.map((featurePage) => (
              <SingleFeature
                key={featurePage?.id}
                data={featurePage}
                appCode={appCode}
                isActive={activeFeaturePage?.id === featurePage?.id}
                handleItemClick={handleItemClick}
              />
            ))}
          </Col>

          <Col span={md ? 9 : 24} className={styles.imageContainer}>
            <div style={{ height: '100%', position: 'relative' }}>
              <Image
                src={activeFeaturePage?.attributes?.image?.data?.attributes?.url}
                width={activeFeaturePage?.attributes?.image?.data?.attributes?.width || 1000}
                height={activeFeaturePage?.attributes?.image?.data?.attributes?.height || 1000}
                alt="Features cover image"
                className={styles.featuresPageImage}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row style={{ display: 'flex', gap: '20px' }}>
          <Col span={md ? 9 : 24} className={styles.imageContainer}>
            <div style={{ height: '100%', position: 'relative' }}>
              <Image
                src={activeFeaturePage?.attributes?.image?.data?.attributes?.url}
                width={activeFeaturePage?.attributes?.image?.data?.attributes?.width || 1000}
                height={activeFeaturePage?.attributes?.image?.data?.attributes?.height || 1000}
                alt="Features cover image"
                className={styles.featuresPageImage}
              />
            </div>
          </Col>
          <Col span={md ? 14 : 24} className={styles.featuresPageItemsContainer}>
            {data?.featuresPages?.data?.map((featurePage) => (
              <SingleFeature
                key={featurePage?.id}
                data={featurePage}
                appCode={appCode}
                isActive={activeFeaturePage?.id === featurePage?.id}
                handleItemClick={handleItemClick}
              />
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
};
