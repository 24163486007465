import {
  ComponentBlocksCommonAskedQuestions,
  ComponentBlocksRichSectionTitle,
  ComponentBlocksSectionTitle,
  FeatureRelationResponseCollection,
  PlanEntity,
  PricingPage,
} from 'services/graphql/cms';
import { PricingCaq } from '../../pricing/CAQ';
import { PricingFeatures } from '../../pricing/Features';
import { PricingJumbotron } from '../../pricing/Jumbotron';
import { PricingPlan } from '../../pricing/Plan';
import { PricingTryGold } from '../../pricing/TryGold';

type Props = {
  pricingData: PricingPage;
  plans: PlanEntity[];
};

export type FeaturesGroup = 'TheBoatDB' | 'TheBoatApp' | 'General';

export const Pricing = ({ pricingData, plans }: Props) => {
  const {
    title,
    description,
    featuredImage,
    reachSectionTitle,
    comparePlansTitle,
    features,
    faq_title: faqTitle,
    FAQ,
  } = pricingData;

  return (
    <main>
      <PricingJumbotron title={title} description={description} image={featuredImage} />

      <PricingTryGold info={reachSectionTitle as ComponentBlocksRichSectionTitle} planCards={pricingData} />

      <PricingFeatures features={features as FeatureRelationResponseCollection} />

      <PricingPlan
        features={features as FeatureRelationResponseCollection}
        title={comparePlansTitle as ComponentBlocksSectionTitle}
        plans={plans}
      />

      <PricingCaq title={faqTitle as string} faq={FAQ as ComponentBlocksCommonAskedQuestions[]} />
    </main>
  );
};
