import { FC } from 'react';
import styles from './PricingPlan.module.scss';
import { PricingPlanProps } from './PricingPlan.props';
import { Enum_Feature_Group, FeatureEntity } from 'services/graphql/cms';
import CompareTable from './CompareTable';
import TableHead from './Head';

const PricingPlan: FC<PricingPlanProps> = ({ features, title, plans }) => {
  const sortedFeatures = features?.data.sort(
    (a: FeatureEntity, b: FeatureEntity) => a!.attributes!.order - b!.attributes!.order,
  );

  const extendPlans = [
    {
      id: '0',
      attributes: {
        title: null,
        icon: null,
        price: null,
      },
    },
    ...plans,
  ];

  return (
    <section className={styles.container} id="pricing__plan">
      <div className={styles.containerContent}>
        <h1 className={styles.contentTitle}>{title.title}</h1>
        <div className={styles.contentText} dangerouslySetInnerHTML={{ __html: `${title.description}` }} />
      </div>

      <div className={styles.containerTables}>
        <TableHead plans={extendPlans} />

        {Object.values(Enum_Feature_Group)
          .reverse()
          .map((group) => (
            <CompareTable
              key={group}
              features={sortedFeatures.filter((item: FeatureEntity) => item?.attributes?.group === group)}
              group={group}
              plans={extendPlans}
            />
          ))}
      </div>
    </section>
  );
};

export { PricingPlan };
