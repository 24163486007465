import { FC, ReactNode, useState } from 'react';
import styles from './FeaturePage.module.scss';
import { CustomBreadcrumb } from '../../../CustomBreadcrumb';
import { LinkItem } from '../../../CustomBreadcrumb/CustomBreadcrumb.props';
import { FeaturesPage } from 'services/graphql/cms';
import Image from 'next/image';
import { VideoModal, VideoModalState } from '../../../VideoModal';
import { PlayBtn } from '../../../CustomIcons';
import { Typography } from 'antd';

type Props = {
  featuredPage: FeaturesPage;
  badge?: boolean;
  cta?: ReactNode;
  featureIcon?: ReactNode;
  app?: string;
};

const { Title } = Typography;

const FeaturePage: FC<Props> = ({ featuredPage, badge, cta, featureIcon }) => {
  const { slug, title, description, image, youtube_video_url: videoUrl } = featuredPage;
  const [isModalVisible, setIsModalVisible] = useState<VideoModalState>('NOT_OPENED');

  const showVideoModal = (): void => {
    setIsModalVisible('OPEN');
  };

  const closeVideoModal = (): void => {
    setIsModalVisible('CLOSED');
  };

  const breadcrumbs: LinkItem[] = [
    {
      href: '/',
      slug: 'home',
      title: 'Home',
    },
    {
      href: '/features',
      slug: 'features',
      title: 'Features',
    },
    {
      href: `/features/${slug}`,
      slug: slug,
      title: title,
    },
  ];

  const imgUrl = image?.data?.attributes?.url;
  const imgAlt = image?.data?.attributes?.alternativeText;

  return (
    <section className={styles.container}>
      <div className={styles.jumbotron}>
        <CustomBreadcrumb items={breadcrumbs} />
      </div>

      <div className={styles.containerWrapper}>
        <div className={styles.containerHead}>
          <div className={styles.headContent}>
            {badge && <p className={styles.containerBadge}>{badge}</p>}
            <Title className={styles.contentTitle}>{title}</Title>

            <div className={styles.description} dangerouslySetInnerHTML={{ __html: `${description}` }}></div>
          </div>
          <div className={styles.headIcon}>{featureIcon}</div>
        </div>

        <div className={styles.containerBody}>
          <div className={styles.bodyContent}>{cta && <div className={styles.ctaWrapper}>{cta}</div>}</div>

          <div className={styles.bodyImage}>
            <div className={styles.imageWrap}>
              <Image src={imgUrl ? imgUrl : ''} alt={imgAlt ? imgAlt : title} width={675} height={416} quality={80} />
              {videoUrl ? <PlayBtn onClick={showVideoModal} className={styles.playBtn} /> : null}
            </div>
          </div>
        </div>
      </div>
      <VideoModal isModalVisible={isModalVisible} closeVideoModal={closeVideoModal} src={videoUrl ? videoUrl : ''} />
    </section>
  );
};

export { FeaturePage };
