import { Pagination } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useCallback } from 'react';
import { GetPostsCardItem } from 'services';
import { PostItemListCard } from '../../../BlogUi';
import { BlogsPageProps } from './BlogsPage.props';
import styles from './BlogsPage.module.scss';

const BlogsPage: FC<BlogsPageProps> = ({ posts, meta }) => {
  const router = useRouter();

  const handleOnPageChange = useCallback(
    (page: number, pageSize: number = 10) => {
      router.push({ query: { ...router.query, page, pageSize } });
    },
    [router],
  );

  return (
    <>
      {posts.map((post: GetPostsCardItem) => (
        <Link className={styles.itemPost} key={post.id} href={`/${post.attributes?.slug}`}>
          <PostItemListCard post={post} />
        </Link>
      ))}
      <Pagination
        onChange={handleOnPageChange}
        pageSize={meta?.pagination.pageSize}
        total={meta?.pagination?.total || 10}
        current={meta?.pagination?.page || 1}
        responsive
      />
    </>
  );
};

export { BlogsPage };
