/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { PlanEntity } from 'services/graphql/cms';
import styles from './PricingPlan.module.scss';
import { PlanIcon } from '../../PlanIcon';
import { Grid } from 'antd';

type Props = {
  plans: (
    | PlanEntity
    | {
        id: string;
        attributes: {
          title: null;
          icon: null;
          price: null;
        };
      }
  )[];
};

const TableHead: React.FC<Props> = ({ plans }) => {
  const { md, xl } = Grid.useBreakpoint();

  const background = '#F4F4F5';

  return (
    <table className={styles.containerHead}>
      <thead>
        <tr>
          {plans.map((item) => {
            if (item?.attributes?.title !== null) {
              return (
                <th
                  key={item.id}
                  style={{
                    backgroundColor: Number(item.id) > 1 ? background : '#F9F9FA',
                  }}
                >
                  <div className={styles.headerTableCell}>
                    <div>
                      {item?.attributes?.icon ? (
                        <PlanIcon
                          name={item?.attributes?.title?.toLowerCase()}
                          styles={{ fontSize: '1.4em', marginRight: 10 }}
                        />
                      ) : null}
                      <p>{item?.attributes?.title !== 'null' && item?.attributes?.title}</p>
                    </div>

                    <div>
                      {item?.attributes?.price?.currency?.data?.attributes?.symbol}
                      {item?.attributes?.price?.amount}
                      /year
                    </div>
                  </div>
                </th>
              );
            }

            return <th key={item.id} colSpan={xl ? 0 : 2} style={{ display: md ? 'table-cell' : 'none' }} />;
          })}
        </tr>
      </thead>
    </table>
  );
};

export default TableHead;
