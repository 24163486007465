import { FC } from 'react';
import styles from '../AllFeaturesPage.module.scss';
import { ArrowRightOutlined } from '@ant-design/icons';
import Link from 'next/link';
import { Col, Row, Button } from 'antd';
interface SingleFeatureProps {
  data: any;
  appCode: string;
  isActive: boolean;
  handleItemClick: (dataInput: any) => void;
}

const SingleFeature: FC<SingleFeatureProps> = ({ data, appCode, isActive, handleItemClick }) => (
  <div
    className={styles.singleFeaturePageItemContainer}
    onMouseEnter={() => handleItemClick(data)}
    onClick={() => handleItemClick(data)}
  >
    <Row>
      <Col span={1}>{isActive ? <div className={styles.verticalActiveLine}></div> : <div></div>}</Col>

      <Col span={23}>
        <div className={styles.singleFeaturePageItemTitle}>{data?.attributes?.title}</div>
        <div
          className={styles.singleFeaturePageItemDescription}
          dangerouslySetInnerHTML={{ __html: data?.attributes?.description || '' }}
        ></div>
        <Link href={`${appCode === 'TBA-WEB' ? 'services' : 'features'}/${data?.attributes?.slug}`}>
          <Button type="link" className={styles.singleFeaturePageItemButton}>
            Learn more{<ArrowRightOutlined />}
          </Button>
        </Link>
      </Col>
    </Row>
  </div>
);

export default SingleFeature;
