import { Breadcrumb } from 'antd';
import Link from 'next/link';
import { FC } from 'react';
import styles from './CustomBreadcrumb.module.scss';
import { CustomBreadcrumbProps } from './CustomBreadcrumb.props';

const CustomBreadcrumb: FC<CustomBreadcrumbProps> = ({ items }) => {
  if (!items.length) {
    <Breadcrumb className={styles.container}>
      <Breadcrumb.Item className={styles.containerItem}>
        <Link href="/">Home</Link>
      </Breadcrumb.Item>
    </Breadcrumb>;
  }

  return (
    <Breadcrumb className={styles.container}>
      {items.map((item) => (
        <Breadcrumb.Item key={item.slug} className={styles.containerItem}>
          <Link href={item.href}>{item.title}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export { CustomBreadcrumb };
