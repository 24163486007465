import React, { useMemo } from 'react';
import { Tabs } from 'antd';
import { useRouter } from 'next/router';
import { AppTabsProps } from './AppTabs.props';
import styles from './AppTabs.module.scss';

const AppTabs: React.FC<AppTabsProps> = ({ tabs }) => {
  const router = useRouter();
  const onChange = (key: string) => {
    router.replace({ query: { app: key } });
  };

  const activeKey = useMemo<string>(() => router?.query?.app as string || 'TBA-WEB', [router?.query?.app]);

  return (
    <div className={styles.categoryTabs}>
      <Tabs
        defaultActiveKey="TBA-WEB"
        activeKey={activeKey}
        onChange={onChange}
        items={tabs}
      />
    </div>
  );
};

export default AppTabs;
