/* eslint-disable jsx-a11y/anchor-is-valid */
import { CalendarOutlined } from '@ant-design/icons';
import { Button, Timeline } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useCallback, useMemo } from 'react';
import { useInfiniteChangelogs } from 'services';
import { GetChangelogsVariables } from 'services/graphql/cms';
import styles from './LogsTimeline.module.scss';
import { LogsTimelineProps } from './LogsTimeline.props';

const LogsTimeline: FC<LogsTimelineProps> = ({ code }) => {
  const router = useRouter();

  const variables = useMemo<GetChangelogsVariables>(
    () => ({
      page: 1,
      code,
    }),
    [code],
  );

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteChangelogs(variables);

  const handleGetMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className={styles.logsTimelineWrapper}>
      <div className={styles.header}>
        <h3>Timeline</h3>
      </div>
      <div className={styles.timelineWrapper}>
        <Timeline>
          {data?.pages.map((itemPage, index) => (
            <div key={itemPage.changelogs?.meta.pagination.page}>
              {itemPage.changelogs?.data.map((itemLog) => (
                <Timeline.Item dot={<CalendarOutlined />} color="#53576F" key={itemLog.id || ''}>
                  <Link
                    href={{ query: { ...router.query, page: index + 1 }, hash: `log-item-${itemLog.id}` }}
                    className={styles.timelineItemHeader}
                  >
                    {itemLog?.attributes?.version !== '' && <h4>{itemLog?.attributes?.version}</h4>}
                    <p>{itemLog?.attributes?.title}</p>
                    <span className={styles.timelineItemDate}>{itemLog?.attributes?.date}</span>
                  </Link>
                </Timeline.Item>
              ))}
            </div>
          ))}
        </Timeline>

        {hasNextPage && (
          <div className={styles.more}>
            <Button loading={isFetchingNextPage} disabled={isFetchingNextPage} type="link" onClick={handleGetMore}>
              Load more
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export { LogsTimeline };
