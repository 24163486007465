import { DownOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { FC } from 'react';
import { ComponentBlocksCommonAskedQuestions } from 'services/graphql/cms';
import styles from './PricingCaq.module.scss';
import { PricingCaqProps } from './PricingCaq.props';

const { Panel } = Collapse;

const PricingCaq: FC<PricingCaqProps> = ({ title, faq }) => (
  <section className={styles.caq}>
    <h1>{title}</h1>
    <Collapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => <DownOutlined className={isActive ? 'active' : ''} />}
    >
      {faq.length !== 0 &&
        faq
          .sort((a: ComponentBlocksCommonAskedQuestions, b: ComponentBlocksCommonAskedQuestions) => a!.order - b!.order)
          .map((item: ComponentBlocksCommonAskedQuestions) => (
            <Panel header={item.question as ComponentBlocksCommonAskedQuestions['question']} key={item.id}>
              <div dangerouslySetInnerHTML={{ __html: `${item.answer}` }} />
            </Panel>
          ))}
    </Collapse>
  </section>
);

export { PricingCaq };
