import { Grid } from 'antd';
import Image from 'next/image';
import { FC } from 'react';
import { BreadCrumb } from '../../BreadCrumb';
import styles from './PricingJumbotron.module.scss';
import { PricingJumbotronProps } from './PricingJumbotron.props';

const PricingJumbotron: FC<PricingJumbotronProps> = ({ title, description, image }) => {
  const { xs, lg } = Grid.useBreakpoint();

  const breadCrumb = [0, 1];

  const imgUrl = image?.data?.attributes?.url;
  const alt = image?.data?.attributes?.alternativeText;

  return (
    <>
      <section className={styles.container}>
        <BreadCrumb breadCrumb={breadCrumb} pageName="pricing" />

        <div className={styles.containerWrapper}>
          <div className={styles.contentImage}>
            <Image src={imgUrl ? imgUrl : ''} alt={alt ? alt : title} width={383} height={306} />
          </div>

          {!xs && (
            <div className={styles.containerContent}>
              <h1 className={styles.contentTitle}>{title}</h1>
              {lg && <div className={styles.contentText} dangerouslySetInnerHTML={{ __html: `${description}` }} />}
            </div>
          )}
        </div>
      </section>

      {!lg && (
        <div className={styles.bottomContent}>
          {xs && <h1 className={styles.containerTitle}>{title}</h1>}
          <div className={styles.containerText} dangerouslySetInnerHTML={{ __html: `${description}` }} />
        </div>
      )}
    </>
  );
};

export { PricingJumbotron };
