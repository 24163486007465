import { MailOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { FacebookIcon, LinkedinIcon, RedditIcon, TwitterIcon } from '../CustomIcons';
import styles from './SocialShare.module.scss';
import { SocialShareProps } from './SocialShare.props';

const SocialShare: FC<SocialShareProps> = ({ postUrl, title, summary }) => {
  const socialItems = [
    {
      url: `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`,
      icon: <FacebookIcon />,
    },
    {
      url: `https://twitter.com/intent/tweet?url=${postUrl}&text=${title}`,
      icon: <TwitterIcon />,
    },
    {
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}&title=${title}`,
      icon: <LinkedinIcon />,
    },
    {
      url: `https://reddit.com/submit?url=${postUrl}&title=${title}`,
      icon: <RedditIcon />,
    },
    {
      url: `
      mailto:Support@MarineDataCloud.com?subject=Check this blog: ${title}&body=${summary}... - link: ${postUrl}`,
      icon: <MailOutlined />,
    },
  ];

  return (
    <div className={styles.share}>
      {socialItems.map((item) => {
        const { url, icon } = item;

        return (
          <li className={styles.item} key={item.url}>
            <a href={url} target="_blank" rel="noreferrer" className={styles.itemLink}>
              {icon}
            </a>
          </li>
        );
      })}
    </div>
  );
};

export { SocialShare };
