import { Empty, Pagination, Skeleton } from 'antd';
import { useRouter } from 'next/router';
import { FC, useCallback } from 'react';
import cn from 'classnames';
import { BreadCrumb } from '../../../BreadCrumb';
import styles from './ChangelogsPage.module.scss';
import { ChangelogsPageProps } from './ChangelogsPage.props';
import AppTabs from './components/AppTabs/AppTabs';
import { LogsList } from './components/LogsList';
import { LogsTimeline } from './components/LogsTimeline';

const ChangelogsPage: FC<ChangelogsPageProps> = ({ changelogs, tabs, isLoading, code }) => {
  const router = useRouter();

  const handleOnPageChange = useCallback(
    (page: number) => {
      router.push({ query: { ...router.query, page } });
    },
    [router],
  );

  return (
    <div>
      <section className={styles.container}>
        <div className={styles.jumbotron}>
          <BreadCrumb pageName="changelog" breadCrumb={[1]} />
          <h1 className={styles.jumbotronTitle}>Changelogs</h1>
        </div>
      </section>
      {!!tabs?.length && <AppTabs tabs={tabs} />}
      <div className={`${styles.content} ${cn({ [styles.contentNoTabs]: !tabs?.length })}`}>
        <Skeleton paragraph={{ rows: 20 }} loading={isLoading}>
          {changelogs?.data.length ? (
            <div className={styles.contentLogs}>
              <LogsList changelogs={changelogs} />
              <LogsTimeline code={code} />
            </div>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className={styles.emptyLogs}
              description="No changelogs found for this application."
            />
          )}
        </Skeleton>
        <Pagination
          onChange={handleOnPageChange}
          pageSize={changelogs?.meta?.pagination?.pageSize || 10}
          total={changelogs?.meta.pagination.total}
          current={changelogs?.meta.pagination.page}
        />
      </div>
    </div>
  );
};

export { ChangelogsPage };
