import { FC, useState } from 'react';
import { PricingCard } from '../Card';
import styles from './PricingTryGold.module.scss';
import { PricingTryGoldProps } from './PricingTryGold.props';
import { Grid } from 'antd';
import { useKeenSlider } from 'keen-slider/react';
import { CarouselControllersTop } from '../../CarouselControllersTop';

const PricingTryGold: FC<PricingTryGoldProps> = ({ info, planCards }) => {
  const { xs, xxl } = Grid.useBreakpoint();
  const [currentSlide, setCurrentSlide] = useState(0);

  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: 'snap',
    slides: {
      spacing: 0,
      perView: 'auto',
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const planCardsData = planCards?.planCards;

  return (
    <div className={styles.container}>
      <div className={styles.containerContent}>
        <div className={styles.contentTitle} dangerouslySetInnerHTML={{ __html: `${info?.title}` }} />
        <p className={styles.contentText} dangerouslySetInnerHTML={{ __html: `${info?.description}` }} />
      </div>

      <div className={styles.containerCards}>
        <div className={styles.carouselControllerContainer}>
          {!xxl && (
            <CarouselControllersTop
              shape="default"
              currentSlide={currentSlide}
              instanceRef={instanceRef}
              nrOfDots={xs ? planCardsData?.length || 0 : 0}
            />
          )}
        </div>

        <div ref={ref} className={`keen-slider, ${styles.planCardsSliderContainer}`}>
          {planCardsData?.length
            ? planCardsData.map(
                (plan) =>
                  plan?.planName && (
                    <div key={plan.id} className="keen-slider__slide">
                      <PricingCard plan={plan} />
                    </div>
                  ),
              )
            : null}
        </div>
      </div>
    </div>
  );
};

export { PricingTryGold };
