import { FC } from 'react';
import { LogItem } from '../LogItem';
import styles from './LogsList.module.scss';
import { LogsListProps } from './LogsList.props';

const LogsList: FC<LogsListProps> = ({ changelogs }) => (
  <div className={styles.logsListWrapper}>
    {changelogs?.data.map((item) => (
      <LogItem key={item.id} attributes={item.attributes} id={item.id} />
    ))}
  </div>
);

export { LogsList };
